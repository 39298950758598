import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faFaucetDrip, faListOl, faPenClip, faWrench } from "@fortawesome/free-solid-svg-icons";

import OrgHomeHeader from "./orgHomeHeader";


const Home = () => {
    return (
      <>
        <OrgHomeHeader orgName={""} />
        <div className="page">
          <div className="org-home">
            <div className="section-white">
              <div className="group-welcome">
                <h2>Tester Registration</h2>
                <p>
                  Please access this form using the unique link{" "}
                  <strong>provided by your city or organization</strong>. If you
                  are unable to access the URL in that link, please contact{" "}
                  <a href="https://www.swiftcomply.com/customer-support/">
                    SwiftComply Support
                  </a>
                  .
                </p>
              </div>

              <div className="group">
                <div className="module-green">
                  <FontAwesomeIcon icon={faFaucetDrip} className="icon" />
                  <h3>Registration Info</h3>
                  <p>
                    Once your organization's new program is launched, you will
                    receive an email confirmation with details on how to
                    complete the registration process, and create a password to
                    access SwiftComply.
                  </p>
                </div>
                <div className="module-black">
                  <FontAwesomeIcon icon={faListOl} className="icon" />
                  <h3>Requirements</h3>
                  <p>
                    Emails must be unique for both admin and tester users. If
                    you have already submitted this form for another
                    organization, you must submit it again for the organization
                    you are currently registering for.
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="module-purple">
                  <FontAwesomeIcon icon={faPenClip} className="icon" />
                  <h3>Admin Users</h3>
                  <p>
                    Admin users are for service provider administration, within
                    certain configuration settings they can enter test reports
                    on behalf of their service provider testers, no
                    certification information is required.
                  </p>
                </div>
                <div className="module-blue">
                  <FontAwesomeIcon icon={faWrench} className="icon" />
                  <h3>Tester Users</h3>
                  <p>
                    Tester users have the ability to enter test reports.
                    Certification information and associated documents are
                    required. Test kit information is optional at registration.
                  </p>
                </div>
              </div>
            </div>

            <div className="section-grey">
              <div className="group-faq">
                <div className="module">
                  <FontAwesomeIcon icon={faCircleQuestion} className="icon" />
                  <h3>Frequently Asked Questions</h3>
                  <details>
                    <summary>What is SwiftComply Backflow?</summary>
                    <p>
                      We work with cities and water authorities across the globe
                      that are looking to modernize their operations and future
                      proof of their compliance programs against the changing
                      regulatory landscape.
                    </p>
                  </details>
                  <br />
                  <details>
                    <summary>Why fill this form out?</summary>
                    <p>
                      To have updated information with the city and it is
                      necessary in order to submit a test through the new
                      program.
                    </p>
                  </details>
                </div>
              </div>
            </div>

            <div className="section-blue"></div>
          </div>
        </div>
      </>
    );
}

export default Home;